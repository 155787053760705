<template>
    <TransitionHeight>
        <div class="flex items-center gap-4">
            <slot name="title-link" />
            <div
                class="group flex grow items-center justify-between gap-4"
                :class="{
                    'cursor-pointer select-none group-hover:text-brew':
                        titleIsClickable,
                }"
                @click="onTitleClick"
            >
                <div
                    :class="
                        isExpanded && titleClassExpanded
                            ? titleClassExpanded
                            : titleClass
                    "
                >
                    <slot
                        name="title"
                        :is-expanded="isExpanded"
                        :toggle-is-expanded="toggleIsExpanded"
                    />
                </div>

                <button
                    v-if="icon === 'plusminus'"
                    data-cy="collapse-toggle"
                    class="-mr-2 p-2"
                    @click="onIconClick"
                >
                    <div
                        class="relative flex h-[30px] w-[30px] items-center justify-center rounded ring-1 ring-inset ring-grind transition"
                        :class="{
                            'bg-grind text-white': isExpanded,
                            'hover:bg-brew hover:text-white hover:ring-brew':
                                !titleIsClickable,
                            'group-hover:bg-brew group-hover:text-white group-hover:ring-brew':
                                titleIsClickable,
                        }"
                    >
                        <Icon
                            name="plus"
                            class="text-xl transition"
                            :class="{ 'opacity-0': isExpanded }"
                        />

                        <span
                            class="absolute inset-0 flex items-center justify-center"
                        >
                            <Icon
                                name="minus"
                                class="text-[22px] opacity-0 transition"
                                :class="{ 'opacity-100': isExpanded }"
                            />
                        </span>
                    </div>
                </button>

                <button
                    v-if="icon === 'chevron'"
                    class="flex transition hover:text-brew"
                    :class="{ 'group-hover:text-brew': titleIsClickable }"
                    data-cy="collapse-toggle"
                    @click="onIconClick"
                >
                    <Icon
                        name="chevron-down"
                        class="transition"
                        :class="{ 'rotate-180': isExpanded }"
                    />
                </button>
            </div>
        </div>

        <div v-if="isExpanded" :class="contentClass">
            <slot :is-expanded="isExpanded" />
        </div>
    </TransitionHeight>
</template>

<script setup lang="ts">
const props = withDefaults(
    defineProps<{
        titleIsClickable?: boolean;
        icon: 'chevron' | 'plusminus';
        isExpanded?: boolean;
        titleClass?: string;
        titleClassExpanded?: string;
        contentClass?: string;
    }>(),
    {
        titleIsClickable: false,
        isExpanded: false,
        titleClass: undefined,
        titleClassExpanded: undefined,
        contentClass: undefined,
    },
);

const emit = defineEmits(['toggle']);

const isExpanded = ref(props.isExpanded);

const toggleIsExpanded = () => {
    isExpanded.value = !isExpanded.value;
    emit('toggle', isExpanded.value);
};

const onTitleClick = () => {
    if (props.titleIsClickable) {
        toggleIsExpanded();
    }
};

const onIconClick = () => {
    if (!props.titleIsClickable) {
        toggleIsExpanded();
    }
};

watch(
    () => props.isExpanded,
    (value) => {
        isExpanded.value = value;
    },
);
</script>
